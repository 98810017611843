import { infiniteQueryOptions, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  getNotifications,
  NOTIFICATION_STATUS,
  removeAllNewStatus,
  removeAllNotification,
  removeIdArrNewStatus,
  removeNotifications,
} from '@api';

import { AUTH_USER_QUERY_KEY } from './keys';

const userQueryKeys = {
  notificationList: (params: number) => [AUTH_USER_QUERY_KEY, 'notificationList', params],
};

export const getNotificationInfiniteQueryOptions = ({
  limit = 20,
}: {
  limit?: number;
} = {}) => {
  return infiniteQueryOptions({
    queryKey: userQueryKeys.notificationList(limit),
    queryFn: ({ pageParam: nextOffset }) =>
      getNotifications({ searchParams: { limit, offset: nextOffset } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) => {
      const count = pages.reduce(
        (acc, page) => acc + page.rows.filter((item) => !item.removed).length,
        0,
      );

      const nextOffset = lastPage.count > count ? count : undefined;

      return nextOffset;
    },
    staleTime: 30000,
  });
};

export const useDeleteNotificationMutate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      params: { type: 'all' } | { type: 'list'; ids: number[] } | { type: 'item'; id: number },
    ) => {
      if (params.type === 'all') {
        await removeAllNotification();
      } else if (params.type === 'list') {
        await removeNotifications({ json: { ids: params.ids } });
      } else if (params.type === 'item') {
        await removeNotifications({ json: { id: params.id } });
      }
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: getNotificationInfiniteQueryOptions().queryKey });

      const previousData = queryClient.getQueryData(getNotificationInfiniteQueryOptions().queryKey);

      queryClient.setQueryData(getNotificationInfiniteQueryOptions().queryKey, (input) => {
        if (!input?.pages.length) {
          return input;
        }

        return {
          ...input,
          pages: input.pages.map((page, i) => {
            const isLast = input.pages.length - 1 === i;
            let count = page.count;

            // update count because we are using in for offset in infinite query
            if (isLast) {
              if (variables.type === 'all') {
                count = 0;
              } else if (variables.type === 'item') {
                count -= 1;
              } else if (variables.type === 'list') {
                count -= variables.ids.length;
              }
            }

            return {
              count,
              rows: page.rows.map((row) => {
                const updatedFields =
                  variables.type === 'all' ||
                  (variables.type === 'item' && variables.id === row.id) ||
                  (variables.type === 'list' && variables.ids.includes(row.id))
                    ? {
                        removed: true,
                      }
                    : {};

                return {
                  ...row,
                  ...updatedFields,
                };
              }),
            };
          }),
        };
      });

      return {
        previousData,
      };
    },
    onError: (data, variables, context) => {
      queryClient.setQueryData(
        getNotificationInfiniteQueryOptions().queryKey,
        context?.previousData,
      );
    },
  });
};

export const useMarkAsReadMutate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { type: 'all' } | { type: 'ids'; ids: number[] }) => {
      if (params.type === 'all') {
        await removeAllNewStatus();
      } else {
        await removeIdArrNewStatus({ json: { ids: params.ids } });
      }
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({ queryKey: getNotificationInfiniteQueryOptions().queryKey });

      const previousData = queryClient.getQueryData(getNotificationInfiniteQueryOptions().queryKey);

      queryClient.setQueryData(getNotificationInfiniteQueryOptions().queryKey, (input) => {
        if (!input?.pages.length) {
          return input;
        }

        return {
          ...input,
          pages: input.pages.map((page) => {
            return {
              ...page,
              rows: page.rows.map((row) => {
                const updatedFields =
                  variables.type === 'all' || variables.ids.includes(row.id)
                    ? { status: NOTIFICATION_STATUS.READ }
                    : {};

                return {
                  ...row,
                  ...updatedFields,
                };
              }),
            };
          }),
        };
      });

      return { previousData };
    },
    onError: (data, variables, context) => {
      queryClient.setQueryData(
        getNotificationInfiniteQueryOptions().queryKey,
        context?.previousData,
      );
    },
  });
};
